export default {
  accordiontab: {
    headerAction:
      'flex flex-row-reverse justify-between border-0 border-transparent bg-transparent p-4',
    content: 'border-0 border-transparent bg-transparent p-4',
  },
  autocomplete: {
    input: ({ props }) => ({
      class: [
        {
          'px-4 py-4': props.size == 'large',
          'px-2 py-2': props.size == 'small',
          'p-3': props.size == null,
        },
        'text-surface-600 dark:text-surface-200',
        'placeholder:text-surface-400 dark:placeholder:text-surface-500',
        'bg-surface-0 dark:bg-surface-900',
        'border',
        { 'border-surface-300 dark:border-surface-600': !props.invalid },
        { 'border-red-500 dark:border-red-400': props.invalid },
        // States
        {
          'hover:border-primary': !props.disabled && !props.invalid,
          'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50 dark:focus:ring-primary-400/50 focus:z-10':
            !props.disabled,
          'opacity-60 select-none pointer-events-none cursor-default':
            props.disabled,
        },
      ],
    }),
  },
  carousel: {
    root: {
      class: [
        // Flexbox
        'flex flex-col',
      ],
    },
    content: {
      class: [
        // Flexbox & Overflow
        'flex flex-col overflow-auto',
      ],
    },
    container: () => ({
      class: ['flex flex-row'],
    }),
    previousbutton: {
      class: [
        // Flexbox & Alignment
        'flex justify-center items-center self-center cursor-pointer',

        // Sizing & Overflow
        'overflow-hidden w-8 h-8',

        // Spacing
        'mx-2',

        // Shape
        'rounded-full',

        // Border & Background
        'border-0 bg-transparent',

        // Color
        'text-surface-600',

        // Transitions
        'transition duration-200 ease-in-out',
      ],
    },
    nextbutton: {
      class: [
        // Flexbox & Alignment
        'flex justify-center items-center self-center cursor-pointer',

        // Sizing & Overflow
        'overflow-hidden w-8 h-8',

        // Spacing
        'mx-2',

        // Shape
        'rounded-full',

        // Border & Background
        'border-0 bg-transparent',

        // Color
        'text-surface-600',

        // Transitions
        'transition duration-200 ease-in-out',
      ],
    },
    itemscontent: {
      class: [
        // Overflow & Width
        'overflow-hidden w-full py-4',
      ],
    },
    itemscontainer: () => ({
      class: ['flex flex-row'],
    }),
    item: ({ props }) => ({
      class: [
        // Flexbox
        'flex shrink-0 grow ',

        // Size
        {
          'w-full sm:w-[50%] md:w-[33.333333333333336%]':
            props.orientation !== 'vertical',

          'w-full h-full': props.orientation == 'vertical',
        },
      ],
    }),
  },
  column: {
    headerCell: 'p-3',
    bodyCell: 'p-3',
  },
  datatable: {
    table: 'rounded-lg',
    header: 'bg-white rounded-lg border-gray-200',
  },
  paginator: {
    root: 'bg-gray-100',
  },
  stepper: {
    stepperpanel: {
      number: ({ context }) => ({
        class: [
          context.active
            ? 'bg-blue-700 text-primary-inverse'
            : 'border border-surface-200 dark:border-surface-700 text-surface-900 dark:text-surface-0',
        ],
      }),
    },
  },
  button: {
    root: ({ props, context, parent }) => ({
      class: [
        'relative',
        // Alignments
        'items-center inline-flex text-center align-bottom justify-center',
        // Sizes & Spacing
        'leading-[normal]',
        'rounded-large',
        {
          'px-4 py-3': props.size === null,
          'text-sm py-2 px-3': props.size === 'small',
          'text-xl py-3 px-4': props.size === 'large',
        },
        {
          'w-12 p-0 py-3': props.label == null && props.icon !== null,
        },
        // Shapes
        { 'shadow-lg': props.raised },
        { 'rounded-large': !props.rounded, 'rounded-full': props.rounded },
        {
          'rounded-none first:rounded-l-md last:rounded-r-md':
            parent.instance.$name === 'InputGroup',
        },
        {
          'rounded-none rounded-r-md':
            ['AutoComplete'].indexOf(parent.instance.$name) > -1 ||
            (parent.instance.$name === 'Calendar' &&
              props.pt['data-pc-section'] === 'dropdownbutton'),
        },
        {
          'rounded-md text-white':
            ['Calendar'].indexOf(parent.instance.$name) > -1,
        },
        // Link Button
        { 'text-primary-600 bg-transparent border-transparent': props.link },
        // Plain Button
        {
          'text-white bg-gray-500 border border-gray-500':
            props.plain && !props.outlined && !props.text,
        },
        // Plain Text Button
        { 'text-surface-500': props.plain && props.text },
        // Plain Outlined Button
        {
          'text-surface-500 border border-gray-500':
            props.plain && props.outlined,
        },
        // Text Button
        { 'bg-transparent border-transparent': props.text && !props.plain },
        // Outlined Button
        { 'bg-transparent border': props.outlined && !props.plain },
        // --- Severity Buttons ---
        // Primary Button
        {
          'text-white':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-blue-700':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-blue-700':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Primary Text Button
        {
          'text-blue-700':
            props.text && props.severity === null && !props.plain,
        },
        // Primary Outlined Button
        {
          'text-blue-700 border border-blue-700':
            props.outlined && props.severity === null && !props.plain,
        },
        // Secondary Button
        {
          'text-blue-900 bg-blue-100 hover:bg-blue-300 hover:shadow-wide border-2 border-transparent hover:border-transparent focus:shadow-bluelight focus:border-blue-400':
            props.severity === 'secondary' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Secondary Text Button
        {
          'text-surface-500 dark:text-surface-300':
            props.text && props.severity === 'secondary' && !props.plain,
        },
        // Tertiary Button
        {
          'text-blue-900 bg-transparent hover:shadow-wide border-2 border-transparent hover:border-transparent focus:shadow-wide focus:border-blue-900':
            props.severity === 'tertiary',
        },
        // Success Button
        {
          'text-white dark:text-green-900':
            props.severity === 'success' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-green-500 dark:bg-green-400':
            props.severity === 'success' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-green-500 dark:border-green-400':
            props.severity === 'success' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Success Text Button
        {
          'text-green-500 dark:text-green-400':
            props.text && props.severity === 'success' && !props.plain,
        },
        // Success Outlined Button
        {
          'text-green-500 border border-green-500 hover:bg-green-300/20':
            props.outlined && props.severity === 'success' && !props.plain,
        },
        // Info Button
        {
          'text-white dark:text-surface-900':
            props.severity === 'info' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-blue-500 dark:bg-blue-400':
            props.severity === 'info' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-blue-500 dark:border-blue-400':
            props.severity === 'info' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Info Text Button
        {
          'text-blue-500 dark:text-blue-400':
            props.text && props.severity === 'info' && !props.plain,
        },
        // Info Outlined Button
        {
          'text-blue-500 border border-blue-500 hover:bg-blue-300/20 ':
            props.outlined && props.severity === 'info' && !props.plain,
        },
        // Warning Button
        {
          'text-white dark:text-surface-900':
            props.severity === 'warning' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-orange-500 dark:bg-orange-400':
            props.severity === 'warning' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-orange-500 dark:border-orange-400':
            props.severity === 'warning' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Warning Text Button
        {
          'text-orange-500 dark:text-orange-400':
            props.text && props.severity === 'warning' && !props.plain,
        },
        // Warning Outlined Button
        {
          'text-orange-500 border border-orange-500 hover:bg-orange-300/20':
            props.outlined && props.severity === 'warning' && !props.plain,
        },
        // Help Button
        {
          'text-white dark:text-surface-900':
            props.severity === 'help' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-purple-500 dark:bg-purple-400':
            props.severity === 'help' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-purple-500 dark:border-purple-400':
            props.severity === 'help' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Help Text Button
        {
          'text-purple-500 dark:text-purple-400':
            props.text && props.severity === 'help' && !props.plain,
        },
        // Help Outlined Button
        {
          'text-purple-500 border border-purple-500 hover:bg-purple-300/20':
            props.outlined && props.severity === 'help' && !props.plain,
        },
        // Danger Button
        {
          'text-white dark:text-surface-900':
            props.severity === 'danger' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-red-500 dark:bg-red-400':
            props.severity === 'danger' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-red-500 dark:border-red-400':
            props.severity === 'danger' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Danger Text Button
        {
          'text-red-500 dark:text-red-400':
            props.text && props.severity === 'danger' && !props.plain,
        },
        // Danger Outlined Button
        {
          'text-red-500 border border-red-500 hover:bg-red-300/20':
            props.outlined && props.severity === 'danger' && !props.plain,
        },
        // Contrast Button
        {
          'text-white dark:text-surface-900':
            props.severity === 'contrast' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-surface-900 dark:bg-surface-0':
            props.severity === 'contrast' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-surface-900 dark:border-surface-0':
            props.severity === 'contrast' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Contrast Text Button
        {
          'text-surface-900 dark:text-surface-0':
            props.text && props.severity === 'contrast' && !props.plain,
        },
        // Contrast Outlined Button
        {
          'text-surface-900 dark:text-surface-0 border border-surface-900 dark:border-surface-0':
            props.outlined && props.severity === 'contrast' && !props.plain,
        },
        // --- Severity Button States ---
        'focus:outline-none focus:outline-offset-0 focus:ring',
        // Link
        { 'focus:ring-primary': props.link },
        // Plain
        {
          'hover:bg-gray-600 hover:border-gray-600':
            props.plain && !props.outlined && !props.text,
        },
        // Text & Outlined Button
        {
          'hover:bg-surface-300/20':
            props.plain && (props.text || props.outlined),
        },
        // Primary
        {
          'hover:bg-blue-900 hover:border-blue-900 hover:shadow-wide border-2 border-transparent focus:border-blue-900':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        { 'focus:ring-primary': props.severity === null },
        // Text & Outlined Button
        {
          'hover:bg-primary-300/20':
            (props.text || props.outlined) &&
            props.severity === null &&
            !props.plain,
        },
        // Success
        {
          'hover:bg-green-600 dark:hover:bg-green-300 hover:border-green-600 dark:hover:border-green-300':
            props.severity === 'success' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {
          'focus:ring-green-400/50 dark:focus:ring-green-300/50':
            props.severity === 'success',
        },
        // Text & Outlined Button
        {
          'hover:bg-green-300/20':
            (props.text || props.outlined) &&
            props.severity === 'success' &&
            !props.plain,
        },
        // Info
        {
          'hover:bg-blue-600 dark:hover:bg-blue-300 hover:border-blue-600 dark:hover:border-blue-300':
            props.severity === 'info' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {
          'focus:ring-blue-400/50 dark:focus:ring-blue-300/50':
            props.severity === 'info',
        },
        // Text & Outlined Button
        {
          'hover:bg-blue-300/20':
            (props.text || props.outlined) &&
            props.severity === 'info' &&
            !props.plain,
        },
        // Warning
        {
          'hover:bg-orange-600 dark:hover:bg-orange-300 hover:border-orange-600 dark:hover:border-orange-300':
            props.severity === 'warning' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {
          'focus:ring-orange-400/50 dark:focus:ring-orange-300/50':
            props.severity === 'warning',
        },
        // Text & Outlined Button
        {
          'hover:bg-orange-300/20':
            (props.text || props.outlined) &&
            props.severity === 'warning' &&
            !props.plain,
        },
        // Help
        {
          'hover:bg-purple-600 dark:hover:bg-purple-300 hover:border-purple-600 dark:hover:border-purple-300':
            props.severity === 'help' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {
          'focus:ring-purple-400/50 dark:focus:ring-purple-300/50':
            props.severity === 'help',
        },
        // Text & Outlined Button
        {
          'hover:bg-purple-300/20':
            (props.text || props.outlined) &&
            props.severity === 'help' &&
            !props.plain,
        },
        // Danger
        {
          'hover:bg-red-600 dark:hover:bg-red-300 hover:border-red-600 dark:hover:border-red-300':
            props.severity === 'danger' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {
          'focus:ring-red-400/50 dark:focus:ring-red-300/50':
            props.severity === 'danger',
        },
        // Text & Outlined Button
        {
          'hover:bg-red-300/20':
            (props.text || props.outlined) &&
            props.severity === 'danger' &&
            !props.plain,
        },
        // Contrast
        {
          'hover:bg-surface-800 dark:hover:bg-surface-100 hover:border-surface-800 dark:hover:border-surface-100':
            props.severity === 'contrast' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {
          'focus:ring-surface-500 dark:focus:ring-surface-400':
            props.severity === 'contrast',
        },
        // Text & Outlined Button
        {
          'hover:bg-surface-900/10 dark:hover:bg-[rgba(255,255,255,0.03)]':
            (props.text || props.outlined) &&
            props.severity === 'contrast' &&
            !props.plain,
        },
        // Disabled
        { 'opacity-60 pointer-events-none cursor-default': context.disabled },
        // Transitions
        'transition duration-200 ease-in-out',
        // Misc
        'cursor-pointer overflow-hidden select-none',
      ],
    }),
    label: ({ props }) => ({
      class: [
        'duration-200',
        'font-bold',
        {
          'hover:underline': props.link,
        },
        {
          'flex-1': props.label !== null,
          'invisible w-0': props.label == null,
        },
      ],
    }),
    icon: ({ props }) => ({
      class: [
        'mx-0',
        {
          'mr-2': props.iconPos == 'left' && props.label != null,
          'ml-2 order-1': props.iconPos == 'right' && props.label != null,
          'mb-2': props.iconPos == 'top' && props.label != null,
          'mt-2': props.iconPos == 'bottom' && props.label != null,
        },
      ],
    }),
    loadingicon: ({ props }) => ({
      class: [
        'h-4 w-4',
        'mx-0',
        {
          'mr-2': props.iconPos == 'left' && props.label != null,
          'ml-2 order-1': props.iconPos == 'right' && props.label != null,
          'mb-2': props.iconPos == 'top' && props.label != null,
          'mt-2': props.iconPos == 'bottom' && props.label != null,
        },
        'animate-spin',
      ],
    }),
    badge: ({ props }) => ({
      class: [
        {
          'm-0 w-4 h-4 leading-none flex items-center justify-center':
            props.badge,
        },
        // Secondary Button
        {
          'text-blue-700 bg-blue-100':
            props.badge && props.badgeSeverity === 'secondary',
        },
      ],
    }),
  },
  checkbox: {
    root: {
      class: [
        'relative',
        'inline-flex',
        'align-bottom',
        'w-6',
        'h-6',
        'cursor-pointer',
        'select-none',
      ],
    },
    box: ({ props, context }) => ({
      class: [
        // Alignment
        'flex',
        'items-center',
        'justify-center',
        // Size
        'w-6',
        'h-6',
        // Shape
        'rounded-md',
        'border-2',
        // Colors
        {
          'border-surface-200 bg-surface-0 dark:border-surface-700 dark:bg-surface-900':
            !context.checked && !props.invalid,
          'border-primary bg-primary': context.checked,
        },
        // Invalid State
        { 'border-red-500 dark:border-red-400': props.invalid },
        // States
        {
          'peer-hover:border-primary':
            !props.disabled && !context.checked && !props.invalid,
          'peer-hover:bg-primary-emphasis peer-hover:border-primary-emphasis':
            !props.disabled && context.checked,
          'peer-focus-visible:border-primary-500 dark:peer-focus-visible:border-primary-400 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20 dark:peer-focus-visible:ring-primary-300/20':
            !props.disabled,
          'cursor-default opacity-60': props.disabled,
        },
        {
          '[&>svg]:text-primary-contrast [&>svg]:w-[0.875rem] [&>svg]:h-[0.875rem]':
            context.checked,
        },
        // Transitions
        'transition-colors',
        'duration-200',
      ],
    }),
    input: {
      class: [
        'peer',
        'w-full ',
        'h-full',
        'absolute',
        'top-0 left-0',
        'z-10',
        'p-0',
        'm-0',
        'opacity-0',
        'rounded-md',
        'outline-none',
        'border-2 border-surface-200 dark:border-surface-700',
        'appearance-none',
        'cursor-pointer',
      ],
    },
    icon: ({ state, context }) => ({
      class: [
        // Font
        'text-base leading-none',
        // Size
        'w-4',
        'h-4',
        // Colors
        {
          'text-white dark:text-surface-900': context.checked,
          'text-primary': state.d_indeterminate,
        },
        // Transitions
        'transition-all',
        'duration-200',
      ],
    }),
  },
  inputtext: {
    root: ({ props, context, parent }) => {
      var _a, _b, _c, _d
      return {
        class: [
          // Font
          'leading-[normal]',
          // Flex
          { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },
          // Spacing
          'm-0',
          { 'w-full': props.fluid },
          // Size
          {
            'px-4 py-4': props.size == 'large',
            'px-2 py-2': props.size == 'small',
            'p-3': props.size == null,
          },
          // Shape
          { 'rounded-md': parent.instance.$name !== 'InputGroup' },
          {
            'first:rounded-l-md rounded-none last:rounded-r-md':
              parent.instance.$name == 'InputGroup',
          },
          {
            'border-0 border-y border-l last:border-r':
              parent.instance.$name == 'InputGroup',
          },
          {
            'first:ml-0 -ml-px':
              parent.instance.$name == 'InputGroup' && !props.showButtons,
          },
          // Colors
          'text-surface-600 dark:text-surface-200',
          'placeholder:text-surface-400 dark:placeholder:text-surface-500',
          'bg-surface-0 dark:bg-surface-900',
          'border',
          { 'border-surface-300 dark:border-surface-600': !props.invalid },
          // Invalid State
          { 'border-red-500 dark:border-red-400': props.invalid },
          // States
          {
            'hover:border-primary': !context.disabled && !props.invalid,
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50 dark:focus:ring-primary-400/50 focus:z-10':
              !context.disabled,
            'opacity-60 select-none pointer-events-none cursor-default':
              context.disabled,
          },
          // Filled State *for FloatLabel
          {
            filled:
              (((_a = parent.instance) == null ? void 0 : _a.$name) ==
                'FloatLabel' &&
                context.filled) ||
              (((_c =
                (_b = parent.instance) == null ? void 0 : _b.$parentInstance) ==
              null
                ? void 0
                : _c.$name) == 'FloatLabel' &&
                parent.props.modelValue !== null &&
                ((_d = parent.props.modelValue) == null
                  ? void 0
                  : _d.length) !== 0),
          },
          // Misc
          'appearance-none',
          'transition-colors duration-200',
        ],
      }
    },
  },
  dropdown: {
    root: ({ props, state, parent }) => ({
      class: [
        // Display and Position
        'inline-flex',
        'relative',
        // Shape
        { 'rounded-md': parent.instance.$name !== 'InputGroup' },
        {
          'first:rounded-l-md rounded-none last:rounded-r-md':
            parent.instance.$name == 'InputGroup',
        },
        {
          'border-0 border-y border-l last:border-r':
            parent.instance.$name == 'InputGroup',
        },
        {
          'first:ml-0 ml-[-1px]':
            parent.instance.$name == 'InputGroup' && !props.showButtons,
        },
        // Color and Background
        'bg-surface-0 dark:bg-surface-900',
        'border',
        { 'dark:border-surface-700': parent.instance.$name != 'InputGroup' },
        { 'dark:border-surface-600': parent.instance.$name == 'InputGroup' },
        { 'border-surface-300 dark:border-surface-600': !props.invalid },
        // Invalid State
        'invalid:focus:ring-red-200',
        'invalid:hover:border-red-500',
        { 'border-red-500 dark:border-red-400': props.invalid },
        // Transitions
        'transition-all',
        'duration-200',
        // States
        { 'hover:border-primary': !props.invalid },
        {
          'outline-none outline-offset-0 ring ring-primary-400/50 dark:ring-primary-300/50':
            state.focused,
        },
        // Misc
        'cursor-pointer',
        'select-none',
        {
          'opacity-60': props.disabled,
          'pointer-events-none': props.disabled,
          'cursor-default': props.disabled,
        },
      ],
    }),
    label: ({ props, parent }) => {
      var _a
      return {
        class: [
          //Font
          'leading-[normal]',
          // Display
          'block',
          'flex-auto',
          // Color and Background
          'bg-transparent',
          'border-0',
          {
            'text-surface-800 dark:text-white/80': props.modelValue != null,
            'text-surface-400 dark:text-surface-500': props.modelValue == null,
          },
          'placeholder:text-surface-400 dark:placeholder:text-surface-500',
          // Sizing and Spacing
          'w-[1%]',
          'p-3',
          { 'pr-7': props.showClear },
          //Shape
          'rounded-none',
          // Transitions
          'transition',
          'duration-200',
          // States
          'focus:outline-none focus:shadow-none',
          // Filled State *for FloatLabel
          {
            filled:
              ((_a = parent.instance) == null ? void 0 : _a.$name) ==
                'FloatLabel' && props.modelValue !== null,
          },
          // Misc
          'relative',
          'cursor-pointer',
          'overflow-hidden overflow-ellipsis',
          'whitespace-nowrap',
          'appearance-none',
        ],
      }
    },
    dropdown: {
      class: [
        'flex items-center justify-center',
        'shrink-0',
        'bg-transparent',
        'text-surface-500',
        'w-12',
        'rounded-tr-md',
        'rounded-br-md',
      ],
    },
    overlay: {
      class: [
        'absolute top-0 left-0',
        'border-0 dark:border',
        'rounded-md',
        'shadow-md',
        'bg-surface-0 dark:bg-surface-800',
        'text-surface-800 dark:text-white/80',
        'dark:border-surface-700',
      ],
    },
    listContainer: {
      class: ['max-h-[200px]', 'overflow-auto'],
    },
    list: {
      class: 'py-3 list-none m-0',
    },
    option: ({ context }) => ({
      class: [
        // Font
        'font-normal',
        'leading-none',
        // Position
        'relative',
        'flex items-center',
        // Shape
        'border-0',
        'rounded-none',
        // Spacing
        'm-0',
        'py-3 px-5',
        // Colors
        {
          'text-surface-700 dark:text-white/80':
            !context.focused && !context.selected,
          'bg-surface-200 dark:bg-surface-600/60':
            context.focused && !context.selected,
          'bg-highlight': context.selected,
        },
        //States
        {
          'hover:bg-surface-100 dark:hover:bg-surface-600/80':
            !context.focused && !context.selected,
        },
        { 'hover:bg-highlight-emphasis': context.selected },
        'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-inset focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',
        // Transitions
        'transition-shadow',
        'duration-200',
        // Misc
        { 'pointer-events-none cursor-default': context.disabled },
        { 'cursor-pointer': !context.disabled },
        'overflow-hidden',
        'whitespace-nowrap',
      ],
    }),
    optionGroup: {
      class: [
        'font-bold',
        'm-0',
        'py-3 px-5',
        'text-surface-800 dark:text-white/80',
        'bg-surface-0 dark:bg-surface-600/80',
        'cursor-auto',
      ],
    },
    optionCheckIcon:
      'relative -ms-1.5 me-1.5 text-surface-700 dark:text-white/80 w-4 h-4',
    optionBlankIcon: 'w-4 h-4',
    emptyMessage: {
      class: [
        'leading-none',
        'py-3 px-5',
        'text-surface-800 dark:text-white/80',
        'bg-transparent',
      ],
    },
    header: {
      class: [
        'py-3 px-5',
        'm-0',
        'border-b',
        'rounded-tl-md',
        'rounded-tr-md',
        'text-surface-700 dark:text-white/80',
        'bg-surface-100 dark:bg-surface-800',
        'border-surface-300 dark:border-surface-700',
      ],
    },
    clearIcon: {
      class: ['text-surface-500', 'absolute', 'top-1/2', 'right-12', '-mt-2'],
    },
    loadingIcon: {
      class: 'text-surface-400 dark:text-surface-500 animate-spin',
    },
    transition: {
      enterFromClass: 'opacity-0 scale-y-[0.8]',
      enterActiveClass:
        'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
      leaveActiveClass: 'transition-opacity duration-100 ease-linear',
      leaveToClass: 'opacity-0',
    },
  },
  tabmenu: {
    menu: 'border-b-2 border-gray-200 flex gap-6',
    action: ({ context, state }) => ({
      class: [
        'border-b-2',
        'px-4 py-2',
        {
          'border-blue-700 text-blue-700':
            state.d_activeIndex === context.index,
          'border-transparent': state.d_activeIndex !== context.index,
        },
      ],
    }),
  },
}

<template>
  <div class="flex flex-col gap-4">
    <Alert
      v-if="erroredSupport"
      :title="$t('errors.general_title')"
      type="error"
    >
      <template #content>
        {{ $t('errors.general_msg') }}
      </template>
    </Alert>
    <p> {{ $t('support.subtitle') }}</p>
    <div class="flex flex-col gap-6">
      <!--            <div-->
      <!--              v-if="submittingComplete"-->
      <!--              class="submit-success"-->
      <!--            >-->
      <!--              <p>-->
      <!--                {{ $t('support.success') }}-->
      <!--              </p>-->
      <!--            </div>-->
      <!--            <div v-else>-->
      <InputWrapper
        v-if="categoryOptions"
        name="request_type"
        type="text"
        label="support.contact_label"
        :required="true"
        :field-error="!supportMessage.category?.key && showError"
      >
        <Dropdown
          v-model="supportMessage.category"
          :options="categoryOptions"
          field="request_type"
          icon="fa fa-filter"
          name="request_type"
          data-key="key"
          option-label="label"
          :placeholder="$t('general.select')"
          class="w-full"
        />
      </InputWrapper>
      <InputWrapper
        name="content"
        type="text"
        label="support.object"
        :required="true"
        :field-error="!supportMessage.content && showError"
      >
        <Textarea
          v-model.trim="supportMessage.content"
          rows="3"
        />
      </InputWrapper>
      <div class="flex flex-col-reverse gap-4 sm:flex-row sm:justify-end">
        <Button
          class="p-button-rounded white-space-nowrap"
          :label="$t('general.cancel')"
          severity="secondary"
          @click="$emit('close-dialog')"
        />
        <Button
          data-test="submit-support"
          class="p-button-rounded white-space-nowrap"
          severity="primary"
          :loading="submitting"
          :label="$t('support.cta')"
          @click="submitMessage"
        />
      </div>
    </div>
  </div>
</template>
<script setup>

import {ref, watch, reactive, watchEffect, defineEmits, defineProps} from 'vue'
import { useUserData } from '@/composables/userData'
import {
  ZENDESK_CATEGORY,
  sendSupport,
} from '@/composables/useCustomerSupport'
import InputWrapper from "@/components/common/InputWrapper.vue";
import Button from 'primevue/button';
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import Alert from "@/components/Alert.vue";
import {useI18n} from "vue-i18n";


const props = defineProps({
    category: { type: String, default: null },
  })
    const showError = ref(false)
    const erroredSupport = ref(false)
    const submitting = ref(false)
    const submittingComplete = ref(false)
    const { getUser } = useUserData()
    const currentUser = getUser()
    const supportMessage = reactive({
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      subject: '',
      content: '',
      category: props.category ? ZENDESK_CATEGORY.filter(c => c.key === props.category)[0] : null
    })
    const formValid = ref(false)
    const { t } = useI18n();

    const categoryOptions = ZENDESK_CATEGORY.map(
      (category) => {
      return {key: category.key, label: t(category.label)}
    });

    const validateForm = () => {
      const res =
        supportMessage.first_name.length > 0 &&
        supportMessage.last_name.length > 0 &&
        supportMessage.email.length > 0 &&
        supportMessage.category?.key &&
        supportMessage.content.length > 0
      formValid.value = res
    }
    watchEffect(() => {
      if (currentUser) {
        supportMessage.first_name = currentUser.first_name
        supportMessage.last_name = currentUser.last_name
        supportMessage.email = currentUser.email
        validateForm()
      }
    })
    watch(supportMessage, () => {
      validateForm()
    })

const emit = defineEmits(['close-dialog'])
    const submitMessage = async () => {
      console.log('submitMessage', formValid.value)
      if (!formValid.value) {
        showError.value = true;
        return false;
      }
      submitting.value = true
      erroredSupport.value = false

      sendSupport(supportMessage).then((res) => {
        if (res && !res.error) {
          console.log('res', res)
          emit('close-dialog')
        } else {
          console.log('then', res.error, res)
          erroredSupport.value = true;
        }
        submitting.value = false;
      })
        .catch((e) => {
          submitting.value = false;
          console.log('catch', e)
          erroredSupport.value = true
        })
      submitting.value = false
      submittingComplete.value = true
      window.scrollTo(0,0);
    }

</script>


import {authorizedRequest, manageApiError, transformFilters} from "@/api";
import config from "@/config";


async function getGroups(limit, offset, paramsArgs) {
  const filtersFormatted = transformFilters(paramsArgs.filters);
  let ordering = paramsArgs.sortField ? ((paramsArgs.sortOrder || 0) >= 0 ? '' : '-') + paramsArgs.sortField : '';
  if(paramsArgs.sortField === 'assigned_to_full_name') {
    ordering = ((paramsArgs.sortOrder || 0) >= 0 ? '' : '-') + 'assigned_to__get_full_name';
  }
  const paginationParams = `limit=${limit}&offset=${offset}&ordering=${ordering}`;
  const params = filtersFormatted ? filtersFormatted+'&'+paginationParams : '?'+paginationParams;
  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/organization_groups/${params}`);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function createGroup(id, group) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/organization_groups/`,group);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function updateGroup(id, group) {
  try {
    const result = await authorizedRequest().patch(config.BACKEND_URL+`/api/v2/drf/organization_groups/${group.id}/`, group);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}
async function updateUsersToOrganizationGroup(groupId, usersList) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/organization_groups/${groupId}/update_users/`, {'users_list': usersList});
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}
async function removeUsersToOrganizationGroup(groupId, usersList) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/organization_groups/${groupId}/remove_users/`, {'users_list': usersList});
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}
async function getGroup(groupId) {
  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/organization_groups/${groupId}/`);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function deleteGroupById(id) {
  try {
    const result = await authorizedRequest().delete(config.BACKEND_URL+`/api/v2/drf/organization_groups/${id}/`);
    if (result.status === 204) {
      return { success: true };
    } else {
      return result.data;
    }
  } catch (error) {
    return manageApiError(error);
  }
}


export {
  getGroup,
  getGroups,
  createGroup,
  updateGroup,
  deleteGroupById,
  updateUsersToOrganizationGroup,
  removeUsersToOrganizationGroup,
};

<template>
  <div class="w-full min-h-[100dvh] pt-20 pb-10 md:pt-16 bg-gray-100 relative">
    <div class="flex flex-col items-center mt-[100px] z-10 relative">
      <div class="text-5xl font-extrabold text-gray-900">
        {{ $t('search.title') }}
      </div>
      <div class="text-xl">
        {{ $t('search.description') }}
      </div>
      <div class="flex gap-2 mt-6 px-4 w-full sm:w-[80%] max-w-[718px]">
        <IconField
          icon-position="left"
          class="w-full"
        >
          <InputIcon class="uil uil-search text-lg top-5" />
          <InputText
            v-model="searchQuery"
            class="h-full pl-10"
            autofocus
            @keyup.enter="search"
          />
        </IconField>
        <Button
          class="flex-shrink-0"
          :label="$t('search.search_button')"
          @click="search"
        />
      </div>
    </div>
    <SearchBackground />
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import SearchBackground from "./SearchBackground.vue";
const router = useRouter()
const searchQuery = ref('')

const search = () => {
  router.push({ name: 'search-results', query: { q: searchQuery.value } })
}
</script>

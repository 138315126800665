<template>
  <div class="flex items-center w-full gap-2">
    <div class="w-10 h-10 flex items-center justify-center text-blue-700 p-2 bg-blue-100 rounded">
      <i class="uil uil-building text-xl" />
    </div>
    <div class="text-gray-900 truncate">
      {{ organizationName }}
    </div>
    <div
      v-if="userIsAdmin() || userIsAnyAdmin()"
      data-test="organization-modal"
      class="flex-grow flex justify-end items-center gap-2"
      @click="organizationModal"
    >
      <Button
        severity="interactive"
        icon="uil uil-pen"
        class="w-6 h-6 p-[0!important] text-gray-700 bg-gray-100 hover:bg-gray-300 hover:border-transparent focus:border-blue-700"
      />
    </div>
  </div>
</template>

<script setup>
import {useUserData, useUserRoles} from "@/composables/userData";
import {ModalBus, ModalCommands} from "@/eventBus";
import OrganizationForm from "@/components/management/OrganizationForm.vue";
import {ref} from "vue";
import Button from "primevue/button";

const { getUser } = useUserData()
const { userIsAdmin, userIsAnyAdmin } = useUserRoles();
const currentUser = getUser()
const organizationName = ref('');
if (!organizationName.value) {
  console.log('PASSA')
  organizationName.value = currentUser.organization_info.name;
}

const organizationModal = () => {
  ModalBus.emit(ModalCommands.OPEN, {
    component: OrganizationForm,
    props: {
      fit: true,
      id: currentUser.organization_id,
      record: currentUser.organization,
      small: true
    },
    class:"organization-form",
  })
}
ModalBus.on('OrganizationName:refresh', (data) => {
  organizationName.value = data;
})
</script>
